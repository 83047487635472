import Image from "gatsby-background-image";
import styled from "styled-components";
import { FluidImage } from "../utils/types";

// passing fluid prop to styled component here to stop tsc warning
export const ArticleTitleImageStyled = styled(Image)<{ fluid: FluidImage }>`
  padding-top: 77%; /* padding top 1.3:1 Aspect Ratio (1/1.3 = 0.77) */
  border-radius: ${(props) => props.theme.borderRadius.card};
  &:before,
  &:after {
    border-radius: ${(props) => props.theme.borderRadius.card};
    background-position-y: center !important; // override gatsby-image default of bottom to keep fluid image centered when changing screen size and/or image dimensions
  }
`;

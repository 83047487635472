import styled from "styled-components";

export const MarkDownResetStyles = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1rem 0;
    line-height: normal;
    color: ${(props) => props.theme.colours.foregroundPrimary};
  }
  h2:first-of-type {
    margin-top: 0; /* The first h2 is always the page title on both blogPost and jobPost pages, so removing margin-top to align title with other page titles */
  }
`;

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import ReactMarkdown from "react-markdown";
import { SilentAny } from "../utils/types";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import { PageOuter } from "../components/PageOuter";
import Spacer from "../components/Spacer";
import { SectionContainer } from "../components/SectionStyles";
import { MarkDownResetStyles } from "../components/MarkdownResetStyles";
import { ProjectPreviousAndNextButtons } from "../components/PreviousAndNextButtons";
import { ArrowLink } from "../components/ArrowLink";
import { ProjectCategoryList } from "../components/ProjectCategoryList";
import { ArticleTitleImageStyled } from "../components/ArticleTitleImageStyled";

const Project = ({
  pageContext,
}: //   location,
{
  pageContext: SilentAny;
  //   location: LocationState;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const { project, previousProject, nextProject } = pageContext;
  const {
    title_image,
    title,
    background_section,
    what_we_did_section,
    outcomes_section,
    project_categories,
  } = project.node;

  return (
    <Layout showScrollProgressBar>
      <SEO
        title={title}
        // description={body} // this needs to be an excerpt
        description="Follow the link to read more about this project"
        image={title_image.childImageSharp.fluid.src}
      />
      <PageOuter title={title}>
        <div data-aos="fade">
          <ArticleTitleImageStyled
            fluid={title_image.childImageSharp.fluid}
            backgroundColor={`#ffffff`}
          />
          <Spacer height={4} />
          <h2 tabIndex={0}>{title}</h2>
          {project_categories && (
            <ProjectCategoryList
              projectCategories={project_categories}
              tabIndex={0}
            />
          )}
        </div>

        <div data-aos="fade" data-aos-delay="250">
          <MarkDownResetStyles>
            <Spacer height={2} />
            <SectionContainer tabIndex={0}>
              <h3>Background</h3>
              <ReactMarkdown>{background_section}</ReactMarkdown>
            </SectionContainer>
            <Spacer height={2} />
            <SectionContainer tabIndex={0}>
              <h3>What we did</h3>
              <ReactMarkdown>{what_we_did_section}</ReactMarkdown>
            </SectionContainer>
            <Spacer height={2} />
            <SectionContainer tabIndex={0}>
              <h3>Outcomes</h3>
              <ReactMarkdown>{outcomes_section}</ReactMarkdown>
            </SectionContainer>
          </MarkDownResetStyles>
        </div>
        <ProjectPreviousAndNextButtons
          previousProject={previousProject}
          nextProject={nextProject}
        />
        <Spacer height={2} />
        <div data-aos="fade">
          <ArrowLink
            arrowLeft
            to="/projects"
            ariaLabel="Clickable link to the projects page"
          >
            Back to all projects
          </ArrowLink>
        </div>
      </PageOuter>
    </Layout>
  );
};

export default Project;

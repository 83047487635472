import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Image from "gatsby-background-image";
import { BlogPost, FluidImage, Project } from "../utils/types";
import { convertStringToSlug } from "../utils/functions";
import Spacer from "./Spacer";
import { ArrowLink } from "./ArrowLink";
import styled from "styled-components";

export const BlogPostPreviousAndNextButtons = ({
  previousBlogPost,
  nextBlogPost,
}: {
  previousBlogPost?: BlogPost;
  nextBlogPost?: BlogPost;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const previousBlogPostSlug = previousBlogPost
    ? convertStringToSlug(previousBlogPost.title)
    : undefined;
  const nextBlogPostSlug = nextBlogPost
    ? convertStringToSlug(nextBlogPost.title)
    : undefined;

  const previousBlogPostCategoryName = previousBlogPost?.blog_post_category.blog_post_category_name.toLowerCase();
  const nextBlogPostCategoryName = nextBlogPost?.blog_post_category.blog_post_category_name.toLowerCase();

  return (
    <PreviousNextLinksContainer>
      {nextBlogPost && previousBlogPost ? (
        <>
          <PreviewArrowLinksRow>
            <ArrowLink
              arrowLeft
              to={`/blog/${previousBlogPostCategoryName}/${previousBlogPostSlug}`}
              ariaLabel={`Link to the previous blog post. The previous blog post title is: ${previousBlogPost.title}`}
            />
            <ArrowLink
              to={`/blog/${nextBlogPostCategoryName}/${nextBlogPostSlug}`}
              ariaLabel={`Link to the next blog post. The next blog post title is: ${nextBlogPost.title}`}
            />
          </PreviewArrowLinksRow>
          <PreviewTitle>
            <h5>Next&#58;</h5>
            <h5>{nextBlogPost.title}</h5>
          </PreviewTitle>

          <Spacer height={0.5} />
          <PreviewImageStyled
            alt=""
            fluid={nextBlogPost.title_image.childImageSharp.fluid}
          />
        </>
      ) : !previousBlogPost && nextBlogPost ? (
        <>
          <PreviewArrowLinksRow>
            <ArrowSpacer />
            <ArrowLink
              to={`/blog/${nextBlogPostCategoryName}/${nextBlogPostSlug}`}
              ariaLabel={`Link to the next blog post. The next blog post title is: ${nextBlogPost.title}`}
            />
          </PreviewArrowLinksRow>
          <PreviewTitle>
            <h5>Next&#58;</h5>
            <h5>{nextBlogPost.title}</h5>
          </PreviewTitle>

          <Spacer height={0.5} />
          <PreviewImageStyled
            alt=""
            fluid={nextBlogPost.title_image.childImageSharp.fluid}
          />
        </>
      ) : previousBlogPost && !nextBlogPost ? (
        <>
          <PreviewArrowLinksRow>
            <ArrowLink
              arrowLeft
              to={`/blog/${previousBlogPostCategoryName}/${previousBlogPostSlug}`}
              ariaLabel={`Link to the previous blog post. The previous blog post title is: ${previousBlogPost.title}`}
            />
            <ArrowSpacer />
          </PreviewArrowLinksRow>
          <PreviewTitle>
            <h5>Previous&#58;</h5>
            <h5>{previousBlogPost.title}</h5>
          </PreviewTitle>

          <Spacer height={0.5} />
          <PreviewImageStyled
            alt=""
            fluid={previousBlogPost.title_image.childImageSharp.fluid}
          />
        </>
      ) : (
        <></>
      )}
    </PreviousNextLinksContainer>
  );
};

export const ProjectPreviousAndNextButtons = ({
  previousProject,
  nextProject,
}: {
  previousProject?: Project;
  nextProject?: Project;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const previousProjectSlug = previousProject
    ? convertStringToSlug(previousProject.title)
    : undefined;
  const nextProjectSlug = nextProject
    ? convertStringToSlug(nextProject.title)
    : undefined;

  return (
    <PreviousNextLinksContainer>
      {nextProject && previousProject ? (
        <>
          <PreviewArrowLinksRow>
            <ArrowLink
              arrowLeft
              to={`/projects/${previousProjectSlug}`}
              ariaLabel={`Link to the previous project. The previous project title is: ${previousProject.title}`}
            />
            <ArrowLink
              to={`/projects/${nextProjectSlug}`}
              ariaLabel={`Link to the next project. The next project title is: ${nextProject.title}`}
            />
          </PreviewArrowLinksRow>
          <PreviewTitle>
            <h5>Next&#58;</h5>
            <h5>{nextProject.title}</h5>
          </PreviewTitle>

          <Spacer height={0.5} />
          <PreviewImageStyled
            alt=""
            fluid={nextProject.title_image.childImageSharp.fluid}
          />
        </>
      ) : !previousProject && nextProject ? (
        <>
          <PreviewArrowLinksRow>
            <ArrowSpacer />
            <ArrowLink
              to={`/projects/${nextProjectSlug}`}
              ariaLabel={`Link to the next project. The next project title is: ${nextProject.title}`}
            />
          </PreviewArrowLinksRow>
          <PreviewTitle>
            <h5>Next&#58;</h5>
            <h5>{nextProject.title}</h5>
          </PreviewTitle>

          <Spacer height={0.5} />
          <PreviewImageStyled
            alt=""
            fluid={nextProject.title_image.childImageSharp.fluid}
          />
        </>
      ) : previousProject && !nextProject ? (
        <>
          <PreviewArrowLinksRow>
            <ArrowLink
              arrowLeft
              to={`/projects/${previousProjectSlug}`}
              ariaLabel={`Link to the previous project. The previous project title is: ${previousProject.title}`}
            />
            <ArrowSpacer />
          </PreviewArrowLinksRow>
          <PreviewTitle>
            <h5>Previous&#58;</h5>
            <h5>{previousProject.title}</h5>
          </PreviewTitle>

          <Spacer height={0.5} />
          <PreviewImageStyled
            alt=""
            fluid={previousProject.title_image.childImageSharp.fluid}
          />
        </>
      ) : (
        <></>
      )}
    </PreviousNextLinksContainer>
  );
};

export const PreviousNextLinksContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 220px;
  padding: 0.5rem 1rem 1rem 1rem;
  background: ${(props) => props.theme.colours.background};
  border-top-left-radius: ${(props) => props.theme.borderRadius.card};
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    position: static;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin-top: 2rem;
    @media (max-width: ${(props) => props.theme.screenSize.s}px) {
      flex-direction: column;
      margin-top: 1rem;
    }
  }
`;
export const PreviewArrowLinksRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ArrowSpacer = styled.div`
  width: 48px;
  height: 48px;
  background: ${(props) => props.theme.colours.background};
`;
export const PreviewTitle = styled.div`
  h5 {
    width: 100%;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
// passing fluid prop to styled component here to stop tsc warning
export const PreviewImageStyled = styled(Image)<{ fluid: FluidImage }>`
  // width: 100%;
  // aspect-ratio: 1.3/1;
  // margin: 0 auto;
  // border-radius: ${(props) => props.theme.borderRadius.image};

  padding-top: 77%; /* padding top 1.3:1 Aspect Ratio (1/1.3 = 0.77) */
  border-radius: ${(props) => props.theme.borderRadius.card};
  &:before,
  &:after {
    border-radius: ${(props) => props.theme.borderRadius.card};
    background-position-y: center !important; // override gatsby-image default of bottom to keep fluid image centered when changing screen size and/or image dimensions
  }
`;
